import { cn } from "@/lib/utils";
import { Link } from "@remix-run/react";

type CardProps = {
    date: string;
    title: string;
    description?: string;
    image: string;
    status: "AI_GENERATED" | "VERIFIED" | "COMMUNITY_VERIFIED";
    to: string;
}

export function Card({ date, title, description, image, status, to }: CardProps) {
  const getStatusColor = (status: string) => {
    switch (status) {
      case "VERIFIED":
        return "bg-green-500";
      case "COMMUNITY_VERIFIED":
        return "bg-blue-500";
      default:
        return "bg-yellow-500";
    }
  };

  return (
    <div className="w-full group/card">
      <Link
        to={to}
        className={cn(
          "cursor-pointer overflow-hidden relative card h-64 rounded-md shadow-xl max-w-4xl mx-auto flex",
          "transition-all duration-300 ease-in-out",
          "hover:opacity-100 hover:transform hover:scale-[1.02]"
        )}
      >
        {/* Status Indicator */}
        <div className="absolute top-2 right-2 z-10">
          <span className={cn(
            "w-2 h-2 rounded-full block",
            getStatusColor(status)
          )} />
        </div>

        {/* Left side - Image (1/3) with modern treatment */}
        <div className="relative w-1/3 overflow-hidden">
          {image ? (
            <img 
              className="absolute inset-0 w-full h-full object-cover"
              src={image}
              alt={title}
            />
          ) : (
            <div className="absolute inset-0 bg-gradient-to-br from-indigo-500/30 to-purple-500/30" />
          )}
          
          {/* Gradient overlays */}
          <div className="absolute inset-0 bg-gradient-to-t from-slate-800 via-slate-800/50 to-transparent" />
          <div className="absolute inset-0 bg-gradient-to-r from-slate-800/30 to-transparent" />
          
          {/* Hover effect overlay */}
          <div className="absolute inset-0 bg-gradient-to-br from-indigo-500/20 to-purple-500/20 opacity-0 group-hover/card:opacity-100 transition-opacity duration-300" />
        </div>
        
        {/* Right side - Content (2/3) */}
        <div className="w-2/3 p-4 bg-slate-800 text-white flex flex-col justify-between h-64">
          <div className="flex flex-col">
            <p className="font-normal text-base text-slate-300">
              {date}
            </p>
            <h2 className="font-bold text-xl md:text-xl mt-2 group-hover/card:text-white transition-colors">
              {title}
            </h2>
            {description && (
              <p className="mt-2 text-sm text-slate-300 line-clamp-5 group-hover/card:text-slate-200 transition-colors">
                {description}
              </p>
            )}
          </div>
        </div>
      </Link>
    </div>
  );
}
